/* start vars: m-skip-to-main-content */
/* line 14, scss/80-themes/AffinityDefault/70-modules/skiptomaincontentmodule/module.scss */
.m-skip-to-main-content {
	position: fixed;
	top: -40px;
}

/* line 19, scss/80-themes/AffinityDefault/70-modules/skiptomaincontentmodule/module.scss */
.m-skip-to-main-content:focus {
	z-index: 9999999;
	background-color: rgb(var(--color-dynamic-primary-color));
	padding: 10px 20px;
	color: rgb(var(--color-global-positive));
	top: 0;
	left: 0;
	transition: top .1s ease-in;
	border-bottom-right-radius: 5px;
}

/*# sourceMappingURL=../../../../../true */